import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyAgxQKiLNs94LyYb8Vnnz085399b3hMeLM",
    authDomain: "linkedin-clone-yt-26a17.firebaseapp.com",
    projectId: "linkedin-clone-yt-26a17",
    storageBucket: "linkedin-clone-yt-26a17.appspot.com",
    messagingSenderId: "543327113345",
    appId: "1:543327113345:web:209313f41dc186cba47d80"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();
  const auth = firebase.auth();

  export { db, auth };