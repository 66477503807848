import React from 'react';
import "./Widgets.css";
import InfoIcon from "@material-ui/icons/Info";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"

function Widgets() {

    const newsArticle = (heading, subtitle) => (
        <div className="widgets__article">
            <div className="widgets__articleLeft">
            <FiberManualRecordIcon />
            </div>

            <div className="widgets__articleRight">
                <h4>{heading}</h4>
                <p>{subtitle}</p>
            </div>

        </div>
    )

    return (
        <div className='widgets'>
            <div className="widgets__header">
                <h2>LinkedIn News</h2>
                <InfoIcon />
            </div>
            {newsArticle ("Enty stuff", "Big news baby - 1 reader")}
            {newsArticle ("LinkedIn", "A Blessing or a Curse? - 1 reader")}
            {newsArticle ("Fishing", "Big ole boys - 1 reader")}
            {newsArticle ("Enty stuff", "Big news baby - 1 reader")}
        </div>
        
    )
}

export default Widgets
